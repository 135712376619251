import React, { FC, ReactElement } from 'react';

import { Breakpoint, Dialog } from '@mui/material';

import styles from './dialog_common.module.scss';

type Props = {
  children: ReactElement;
  open: boolean;
  onClose: () => void;
  maxWidth?: Breakpoint;
  fullWidth?: boolean;
  scroll?: boolean;
};

const DialogWrapper: FC<Props> = ({ children, open, onClose, maxWidth = 'lg', fullWidth = true, scroll = true }) => {
  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      className={`${styles.dialog} ${scroll ? styles.scroll : ''}`}
    >
      <img
        src={`/images/iqfu/chat/close_dialog.svg`}
        className={styles.close_dialog_button}
        alt=""
        onClick={() => onClose()}
      />
      {children}
    </Dialog>
  );
};

export default DialogWrapper;
